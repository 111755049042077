<template>
  <div id="sign-in-container" class="">
    <v-container>

      <v-row id="login-panel" justify="center">
        <v-col cols="12" lg="4" md="8" sm="10">  
          <v-card
            elevation="6"
            class="pt-8"
          >
            <v-card-title>
              <v-img id="login-logo" src="../assets/RareCyte_Logo.png" max-width="300" />
            </v-card-title>
            <v-card-text>
              <h2 class="text-center font-weight-bold mt-4 mb-6">Reset Password</h2>
              
              <v-row justify="center" class="mt-5">
                <v-col cols="10">

                  <div v-if="tokenExpired">
                    <v-alert 
                      type="error"
                    >Your reset password token has expired or is invalid. Please click the link below to obtain a new token.
                    </v-alert>

                    <p class="my-8 text-center">
                      <router-link
                        class="sign-in-link"
                        to="/forgot-password"
                      >
                        Get new token
                      </router-link>
                    </p>

                  </div>
                  <div v-else>

                    <div v-if="passwordReset">
                      <v-alert 
                          type="success"
                        >Your password has been updated. Please click the link below to sign in.</v-alert>
                    </div>
                    <div v-else>

                      <div>Hello <strong>{{ firstName }}</strong>. Please update your password with the form below. Your new password must:</div>
                      <div class="mt-2 mb-5">
                        <ul>
                          <li>min of eight characters</li>
                          <li>have at least one uppercase letter</li>
                          <li>contain at least one number</li>
                        </ul>
                      </div>
                      <v-form
                        ref="formPassword"
                        v-model="validPassword"
                        lazy-validation
                      >
                        <v-text-field
                          v-model="form.customerPassword"
                          label="New password *"
                          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="show1 ? 'text' : 'password'"
                          :rules= "[
                            v => !!v || 'New password is required',
                            v => /.{8,}$/.test(v) || 'Password must be at lease 8 characters',
                            v => /(?=.*[a-z])(?=.*[A-Z])/.test(v) || 'Password must contain at least one uppercase and one lowercase letter',
                          ]"
                          required
                          outlined
                          @click:append="show1 = !show1"
                        ></v-text-field>
                        <v-text-field
                          v-model="form.customerPasswordMatch"
                          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                          label="Confirm password *"
                          :type="show2 ? 'text' : 'password'"
                          :rules="[
                            v => !!v || 'Password must match',
                            v => v === this.form.customerPassword || 'Password must match',
                          ]"
                          required
                          outlined
                          @click:append="show2 = !show2"
                        ></v-text-field>
                      
                        <v-btn block
                          :loading="loading"
                          :disabled="loading"
                          color="secondary"
                          elevation="0"
                          @click="updatePassword"
                          large
                        >
                          Update Password
                        </v-btn>
                      </v-form> 
                    </div>  
                    
                    
                    <p class="my-8 text-center">
                      Back to 
                      <router-link
                        class="sign-in-link"
                        to="/"
                      >
                        Sign in
                      </router-link>
                    </p>

                  </div>

                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'ResetPassword',
  data: () => ({
    loading: false,
    form: {
      customerPassword: null,
      customerPasswordMatch: null,
    },
    firstName: '',
    validPassword: true,
    show1: false,
    show2: false,
    passwordReset: false,
    tokenExpired: false,
  }),
  beforeMount (){
    this.passwordReset = false
    this.tokenExpired = false
    
    if(!this.$route.query.token){
      this.$router.push({ path: '/' })
    }else{
      this.getToken()
    }
  },
  methods: {
    getToken () {

      var token = this.$route.query.token
      
      var endpoint = `/portal/forgot-password/${token}`
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
      const request = new Request(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
      })

      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {
        
        if(!jsonResponse.success){

          if( jsonResponse.invalidToken){
            this.$router.push({ path: '/' })
            return
          }else if ( jsonResponse.expiredToken ){
            //expired token response
            this.tokenExpired = true
          }

        }else{
          this.firstName = jsonResponse.firstName
        }
        /*
        if(!jsonResponse.success){
          this.$router.push({ path: '/' })
          return
        }else{
          this.firstName = jsonResponse.firstName
        } 
        */       
       
      })
    },

    updatePassword () {

      if(this.$refs.formPassword.validate()){
        
        this.loading = true
        
        var token = this.$route.query.token

        let postData = {
          pwd: this.form.customerPassword
        }
      
        var endpoint = `/portal/forgot-password/${token}`
        const url = process.env.VUE_APP_API_BASE_URL + endpoint
        const request = new Request(url, {
          method: 'PUT',
          body: JSON.stringify(postData),
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
        })

        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {
          
          if(jsonResponse.success){

            setTimeout(()=>{
              this.$refs.formPassword.reset()
              this.loading = false
              this.passwordReset = true
              return
            },500)

            
          }else{

            if(jsonResponse.msg == 'Invalid token'){
              this.$refs.formPassword.reset()
              this.loading = false
              this.$router.push({ path: '/' })
              return
            }


            setTimeout(()=>{
              
              if(jsonResponse.msg == 'Invalid form data'){
                this.loading = false
                this.$refs.formPassword.reset()
                //show message
              }

              if(jsonResponse.expiredToken){
                this.loading = false
                this.$refs.formPassword.reset()
                //show message token expired
              }
              


            },500)

          }          
        
        })
        

      }
    }
  }
}
</script>
<style lang="scss" scoped>
#sign-in-container{
  width: 100%;
  height: 100%;
  background: url( '../assets/sign-in-bg-dark.jpg') no-repeat center top;
  background-size: cover;
}
#login-panel{
  margin-top:100px;
}
#login-logo{
  margin: 0 auto;
}
.sign-in-link{
  font-weight: bold;
  color: inherit;
}
</style>