<template>
  <div></div>
</template>
<script>
export default {
  name: 'Logout',
  data: () => ({
  }),
  beforeMount() {
    this.$root.$emit("_logoutCustomer")
    this.$router.push({ path: '/' })
  }
}
</script>
