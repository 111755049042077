<template>
  <div>
    <header class="pageHeader text-center">
      <div  v-if="!adminApproved" class="pt-10"></div>
      <div id="page-header" class="font-weight-light">{{ pageTitle }}</div>
    </header>

    <v-container>
      <div v-if="adminApproved">
        <v-row>
          <v-col cols="12" lg="3">

            <!-- begin: Bread Crumbs -->
            <v-breadcrumbs class="ml-n5 mt-n5" :items="breadcrumbs"></v-breadcrumbs>
            <!-- end: Bread Crumbs -->

            <!-- Subcategory Links -->
           
            <v-sheet
              rounded
              outlined
              class="pt-3 px-6 pt-4 pb-2 mb-5"
            >
              <h4 class="overline filter-header">Filter Search Results</h4>
              <v-text-field
                v-model="search"
                label="Filter Results"
                class=""
                outlined
              ></v-text-field>
            </v-sheet>
          </v-col>
          <v-col cols="12" lg="9">

            <v-row justify="center">
              <v-col cols="10">
                
                <h1 class="mb-1 font-weight-thin text-uppercase">{{ documentPageTitle }}</h1>
                
                
                <div class="mb-6">
                  {{ foundDocs }} 
                  <strong>{{ searchPhrase }}</strong>
                </div>
                <!-- begin: DataTable -->
                <v-data-table
                  class="elevation-0"
                  :headers="headers"
                  :items="portalContent"
                  :search="search"
                  :disable-pagination="true"
                  :items-per-page="-1"
                  :hide-default-footer="true"
                  :hide-default-header="true"
                >
                  <template v-slot:item.document_name="{ item }">
                    <div class="py-5">
                      <h2 class="font-weight-regular mb-1">{{ item.document_name }}</h2>
                      <div v-if="item.document_type =='pdf'">
                        <v-icon class="mt-n1 mr-1" color="grey" small>mdi-file-outline</v-icon> 
                        <span class="grey--text">PDF Document</span>
                      </div>
                      <div v-if="item.document_type =='video'">
                        <v-icon class="mt-n1 mr-1" color="grey" small>mdi-video</v-icon> 
                        <span class="grey--text">Video</span>
                      </div>
                      <div v-if="item.document_type =='link'">
                        <v-icon class="mt-n1 mr-1" color="grey" small>mdi-link</v-icon> 
                        <span class="grey--text">Link</span>
                      </div>
                      <div class="mt-4">{{ item.document_description }}</div>
                    </div>
                  </template>

                  <template v-slot:item.document_description="{ item }">
                    <div style="display:none">   {{ item.document_description }}</div>
                  </template>

                  <template v-slot:item.document_id="{ item }">
                    <div v-if="item.document_type =='pdf'">   
                      <v-btn
                        outlined
                        color="#b45b38"
                        @click="viewDocument(item)"
                        small
                      >
                        View / Download
                      </v-btn>
                    </div>
                    <div v-if="item.document_type =='video'">   
                      <v-btn
                        outlined
                        color="#b45b38"
                        @click="viewDocument(item)"
                        small
                      >
                        Watch Video
                      </v-btn>
                    </div>
                    <div v-if="item.document_type =='link'">   
                      <v-btn
                        outlined
                        color="#b45b38"
                        @click="viewDocument(item)"
                        small
                      >
                        Open Link
                      </v-btn>
                    </div>
                  </template>
                </v-data-table>
                <!-- end: DataTable -->
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div> 
      <div v-else>
        
        <v-container>
          <v-row justify="center">
            <v-col cols="12" lg="4">
              <v-alert
                icon="mdi-alert"
                prominent
                text
                type="warning"
              >
                Your account is still under review. To view available guest content, please click the button below.  
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <v-btn
                link
                to="/home"
                elevation="1"
              >
                View Guest Content
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
        
      </div> 
    </v-container>

    <v-overlay :value="overlay">
    <v-progress-circular
      indeterminate
      size="64"
    ></v-progress-circular>
    </v-overlay>

    <v-dialog
    v-model="videoDialogList"
    width="800"
    >
    <v-card class="pa-5">
      <iframe width="100%" height="450" :src="docVideoLinkList" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </v-card>
    </v-dialog>

  </div>
</template>
<script>
import jwt_decode from "jwt-decode"

export default {
  name: 'searchView',
  props: ['search'],
  data: () => ({
    breadcrumbs:  [
      {
        text: 'Home',
        disabled: false,
        href: '/#/'  
      },
      {
        text: 'Portal Search',
        disabled: true,
        href: '/#/search'  
      },
    ],
    subNavLinks: {
      level1: [],
      level2: [],
      level3: [],
      level4: []
    },
    subNavChecks: {
      level1: [],
      level2: [],
      level3: [],
      level4: []
    },
    subNavChecks_selected: {
      level1: [],
      level2: [],
      level3: [],
      level4: []
    },
    filterCbs: {
      level1: [],
      level2: [],
      level3: [],
      level4: []
    },
    filterChips: [],
    searchPhrase: '',
    productCategories: [],
    productCategoriesParsed: [],
    foundDocs: '',
    documentPageTitle: 'Portal Search',
    calories: '',
    portalContent: [],
    overlay: false,
    pageTitle: 'Portal Search',
    adminApproved: true,
    showTable: false,
    itemsPerPageArray: [10, 25, 50],
    filter: {},
    sortDesc: false,
    page: 1,
    itemsPerPage: 10,
    sortBy: 'name',
    keys: [],
    items: [],
    docVideoLinkList: null,
    videoDialogList: false, 
    count: 0
  }),
  computed: {
    headers () {
      return [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'document_name',
        },
        { text: '', value: 'document_description' },
        { text: '', align: 'end', value: 'document_id' },
      ]
    },
    searchQuery() {
      return this.search
    }
  },
  beforeMount() {
    this.$root.$on('_searchContent',(phrase) => {
      this.searchQuery = phrase
      this.searchContent(phrase)
    })
  },
  mounted (){
    if(this.$route.params.search){
      this.searchContent(this.$route.params.search)
    }else{
      this.$router.push({ path: '/home' })
    }
  },
  methods: {
    searchContent(phrase){


      //show loader
      this.overlay = true

      const userObj = JSON.parse(localStorage.getItem('rccpcst'))
      const userInfo = jwt_decode(userObj.jwt)
    
      if(userInfo.ap == 0){
        setTimeout(()=>{
          this.adminApproved = false
          this.overlay = false
          return
        },1000)
      }

      this.portalContent = []

      let postData = {
        action: 'search',
        cats: null,
        acctPermission: userInfo.pId,
        search: phrase
      }

      var endpoint = `/portal/content`
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
      const request = new Request(url, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData),
        mode: 'cors',
      })
      
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {

        let itemStr = (jsonResponse.length == 1)? "item" : "items"

        setTimeout(()=>{
          this.portalContent = jsonResponse
          this.foundDocs = `${jsonResponse.length} ${itemStr} found for `
          this.searchPhrase = `"${phrase}"`
          this.overlay = false
        },1000)
        
      })



    },
    viewDocument (item) {

      const userObj = JSON.parse(localStorage.getItem('rccpcst'))
      const jwt = userObj.jwt
      var docId = item.document_id
      var url, endpoint

      if( item.document_type == 'pdf' || item.document_type == 'other' ) {

        endpoint = `/portal/view-file/${docId}/${jwt}`
        url = process.env.VUE_APP_API_BASE_URL + endpoint

        fetch(url)
        .then(response => response.blob())
        .then(data => window.open(URL.createObjectURL(data)))
        /*
        endpoint = `/portal/view-file/${docId}/${jwt}`
        url = process.env.VUE_APP_API_BASE_URL + endpoint
        window.open(url)
        */
      }else if ( item.document_type == 'link' ) {

        url = item.document_url
        window.open(url)
        
      } else if ( item.document_type == 'video' ) {

        this.docVideoLinkList = item.document_url
        this.videoDialogList = true

      }

    },
  }
}
</script>
<style lang="scss" scoped>

.filter-header{
  color:#53565b;
}
.subnav-link{
  text-decoration: none;
}
header.pageHeader{
  color: #fff;
  width: 100%;
  height: 200px;
  background: url( '../assets/portal-header-bg-3.jpg') no-repeat center top;
  background-size: cover;
  margin-top:-64px;
  
  margin-bottom:50px;
}
#page-header{
  font-size: 2.7rem;
  text-transform: uppercase;
  padding-top:60px;
}
.icon-box{
  height:30px;
  width: 30px;
  text-align: center;
  line-height: 30px;
}

@media only screen and (max-width: 1024px) {
  header.pageHeader{
    width: 100%;
    height: 200px;
    background: url( '../assets/header-bg.png') no-repeat center top;
    background-size: cover;
    margin-top:0px;
    color: #fff;
    padding-top: 20px;
    margin-bottom:50px;
  }
  #page-header{
    font-size: 1.6rem;
    text-transform: uppercase;
  }
}
</style>
