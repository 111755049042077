<template>
  <div id="sign-in-container" class="">
    <v-container>
      <v-row id="login-panel" justify="center">
        <v-col cols="12" lg="4" md="8" sm="10">  
          <v-card
            elevation="6"
            class="pt-8"
          >
            <v-card-title>
              <v-img id="login-logo" src="../assets/RareCyte_Logo.png" max-width="300" />
            </v-card-title>
            <v-card-text>
              <h2 class="text-center font-weight-bold mt-4 mb-6">Forgot Password</h2>
              <p class="text-center">Enter Your E-mail and instructions will be sent to you.</p>
              <v-row justify="center" class="mt-5">
                <v-col cols="10">
                  <v-fade-transition>
                    <div v-show="successAlert" class="pb-4">
                      <v-alert 
                        type="success"
                      >Instructions to reset your password have been sent to {{usrConf}}.</v-alert>
                    </div>
                  </v-fade-transition>
                  <v-fade-transition>
                    <div v-show="errorAlert" class="pb-4">
                      <v-alert 
                        type="error"
                      >{{errorMsg}}</v-alert>
                    </div>
                  </v-fade-transition>
                  <v-form
                    ref="formForgotPassword"
                    v-model="valid"
                    lazy-validation
                    @submit.prevent="requestNewPassword"
                  >
                    <v-text-field
                      v-model="usr"
                      class="mb-5"
                      label="Email"
                      placeholder="Email"
                      outlined
                      prepend-inner-icon="mdi-email-outline"
                      hide-details="auto"
                      :rules= "[
                        v => !!v || 'User Email is required',
                        v => /.+@.+\..+/.test(v) || 'Email must be valid',
                      ]" 
                      required
                    ></v-text-field>
                  
                    <v-btn block
                      elevation="0"
                      color="secondary"
                      :loading="loading"
                      :disabled="loading"
                      large
                      @click="requestNewPassword"
                      type="submit"
                    >
                      Send
                    </v-btn>
                  </v-form>

                  <p class="my-8 text-center">
                    Back to 
                    <router-link
                      class="sign-in-link"
                      to="/"
                    >
                      Sign in
                    </router-link>
                  </p>

                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'ForgotPassword',
  data: () => ({
    valid: true,
    loading: false,
    usr: '',
    errorAlert: false,
    errorMsg: '',
    successAlert: false,
    usrConf: ''
  }),
  methods: {
    requestNewPassword () {
      if( this.$refs.formForgotPassword.validate() ) {
        
        this.loading = true
        
        let postData = {
          email: this.usr,
        }

        var endpoint = '/portal/forgot-password'
        const url = process.env.VUE_APP_API_BASE_URL + endpoint
        const request = new Request(url, {
          method: 'POST',
          mode: 'cors',
          body: JSON.stringify(postData),
          headers: {
            'Content-Type': 'application/json'
          },
        })

        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {
          setTimeout(()=>{

            //console.log(JSON.stringify(jsonResponse))
            
            if(!jsonResponse.success){
              this.errorMsg = jsonResponse.msg
              this.errorAlert=true
              this.$refs.formForgotPassword.reset()
              setTimeout(()=>{
                this.errorAlert = false
                this.errorMsg = ''
              },5000)
            }else{
              this.usrConf = ''
              this.usrConf = this.usr
              this.successAlert=true
              this.$refs.formForgotPassword.reset()
              setTimeout(()=>{
                this.successAlert = false
              },5000)
            }
            
           
            this.loading = false
          },500);  
        })
      }
    }
  },
  components: {
  }
}
</script>
<style lang="scss" scoped>
#sign-in-container{
  width: 100%;
  height: 100%;
  background: url( '../assets/sign-in-bg-dark.jpg') no-repeat center top;
  background-size: cover;
}
#login-panel{
  margin-top:100px;
}
#login-logo{
  margin: 0 auto;
}
/*
#form-container{
  position: absolute;
  right:0;
  height: 100%;
  width: 40%;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 16% 100%);
  background-color: #fff;
}
*/
.sign-in-link{
  font-weight: bold;
  color: inherit;
}

</style>

