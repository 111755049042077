<template>
  <div>
    <v-container>
      <v-row class="justify-center">
        <v-col class="col-md-3" v-for="tile in tiles" :key="tile.category_id">
                  
          <v-card
          v-if="tile.category_slug === 'faq' || tile.category_slug === 'frequently-asked-questions'"
            outlined
            hover
            link
            :href="'/#/' + tile.category_slug"
            min-height="200"
          >
            <v-card-text class="text-center">
              <div class="card-category-title my-6">
                <h2 class="pt-6 pb-3">{{ tile.category_name_display }}</h2>
                <p>{{ tile.category_description }}</p>

              </div>
            </v-card-text>
          </v-card>

          <v-card v-else
            outlined
            hover
            link
            :href="'/#/' + tile.category_id +  '/' + tile.category_slug"
            min-height="200"
          >
            <v-card-text class="text-center">
              <div class="card-category-title my-6">
                <h2 class="pt-6 pb-3">{{ tile.category_name_display }}</h2>
                <p>{{ tile.category_description }}</p>
              </div>
            </v-card-text>
          </v-card>

          
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default { 
  name: 'SubcategoryTiles', 
  props : ['tiles'] 
} 
</script>