var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"sign-in-container"}},[_c('v-container',[_c('v-row',{attrs:{"id":"login-panel","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","lg":"4","md":"8","sm":"10"}},[_c('v-card',{staticClass:"pt-8",attrs:{"elevation":"6"}},[_c('v-card-title',[_c('v-img',{attrs:{"id":"login-logo","src":require("../assets/RareCyte_Logo.png"),"max-width":"300"}})],1),_c('v-card-text',[_c('h2',{staticClass:"text-center font-weight-bold mt-4 mb-6"},[_vm._v("Reset Password")]),_c('v-row',{staticClass:"mt-5",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[(_vm.tokenExpired)?_c('div',[_c('v-alert',{attrs:{"type":"error"}},[_vm._v("Your reset password token has expired or is invalid. Please click the link below to obtain a new token. ")]),_c('p',{staticClass:"my-8 text-center"},[_c('router-link',{staticClass:"sign-in-link",attrs:{"to":"/forgot-password"}},[_vm._v(" Get new token ")])],1)],1):_c('div',[(_vm.passwordReset)?_c('div',[_c('v-alert',{attrs:{"type":"success"}},[_vm._v("Your password has been updated. Please click the link below to sign in.")])],1):_c('div',[_c('div',[_vm._v("Hello "),_c('strong',[_vm._v(_vm._s(_vm.firstName))]),_vm._v(". Please update your password with the form below. Your new password must:")]),_c('div',{staticClass:"mt-2 mb-5"},[_c('ul',[_c('li',[_vm._v("min of eight characters")]),_c('li',[_vm._v("have at least one uppercase letter")]),_c('li',[_vm._v("contain at least one number")])])]),_c('v-form',{ref:"formPassword",attrs:{"lazy-validation":""},model:{value:(_vm.validPassword),callback:function ($$v) {_vm.validPassword=$$v},expression:"validPassword"}},[_c('v-text-field',{attrs:{"label":"New password *","append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show1 ? 'text' : 'password',"rules":[
                          v => !!v || 'New password is required',
                          v => /.{8,}$/.test(v) || 'Password must be at lease 8 characters',
                          v => /(?=.*[a-z])(?=.*[A-Z])/.test(v) || 'Password must contain at least one uppercase and one lowercase letter',
                        ],"required":"","outlined":""},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.form.customerPassword),callback:function ($$v) {_vm.$set(_vm.form, "customerPassword", $$v)},expression:"form.customerPassword"}}),_c('v-text-field',{attrs:{"append-icon":_vm.show2 ? 'mdi-eye' : 'mdi-eye-off',"label":"Confirm password *","type":_vm.show2 ? 'text' : 'password',"rules":[
                          v => !!v || 'Password must match',
                          v => v === this.form.customerPassword || 'Password must match',
                        ],"required":"","outlined":""},on:{"click:append":function($event){_vm.show2 = !_vm.show2}},model:{value:(_vm.form.customerPasswordMatch),callback:function ($$v) {_vm.$set(_vm.form, "customerPasswordMatch", $$v)},expression:"form.customerPasswordMatch"}}),_c('v-btn',{attrs:{"block":"","loading":_vm.loading,"disabled":_vm.loading,"color":"secondary","elevation":"0","large":""},on:{"click":_vm.updatePassword}},[_vm._v(" Update Password ")])],1)],1),_c('p',{staticClass:"my-8 text-center"},[_vm._v(" Back to "),_c('router-link',{staticClass:"sign-in-link",attrs:{"to":"/"}},[_vm._v(" Sign in ")])],1)])])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }