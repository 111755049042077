<template>
  <div>
    <header class="text-center">
      <div class="overline">Welcome to</div>
      <div id="page-header" class="font-weight-thin">RareCyte Customer Portal</div>
    </header>

    <div id="content-container">
      <div v-if="adminApproved">
        <v-container>
          <v-row>
            <v-col class="text-center">
              <h1 class="font-weight-light text-uppercase mb-8">Portal Content</h1>
            </v-col>
          </v-row>
          <v-row justify="center" class="mb-8">
            <v-col cols="12" lg="3"
              v-for="item in categoryTiles"
              :key="item.category_id"
            >
              <v-card
                outlined
                hover
                link
                :href="'/#/' + item.category_slug"
                min-height="250"
              >
                <v-card-text class="text-center">
                  <!--
                  <span class="cat-icon grey lighten-4 mt-5">
                    <v-icon 
                      large
                      color="green darken-3"
                    >
                      {{ item.category_icon }}
                    </v-icon>
                  </span>
                  -->
                  <div class="card-category-title my-6">
                    <h2>{{ item.category_name_display }}</h2>
                  </div>
                  <p>{{ item.category_description }}</p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div v-else class="pb-8">
        <v-container>
          <v-row justify="center">
            <v-col cols="12" lg="4">
              <v-alert
                icon="mdi-alert"
                prominent
                text
                type="warning"
              >
                Your account is still under review. Please feel free to view available guest content below.  
              </v-alert>
            </v-col>
          </v-row>
        </v-container>
      </div>
      
      <div id="guest-container" class="pb-10" v-if="!adminApproved">
        <v-container>  
          <v-row>
            <v-col class="text-center">
              <h1 class="font-weight-light text-uppercase mt-6 mb-6">Guest Content</h1>
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col cols="12" lg="6">
              <v-card
                elevation="0"
                outlined
              >
                <v-card-text class="pa-5">
                  
                  <v-expansion-panels focusable>
                    <v-expansion-panel
                      v-for="(item,i) in guestDocuments"
                      :key="i"
                    >
                      <v-expansion-panel-header class="text-h6 grey--text text--darken-3">{{ item.category }}</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        
                        <div class="pb-6">
                          <v-row class="mt-6"
                            v-for="(doc,k) in item.data"
                            :key="k"
                          >
                            <!--
                            <v-col cols="12" lg="2">
                              <v-icon v-if="doc.document_type =='pdf'" color="red darken-2" >mdi-file-pdf-box</v-icon>
                              <v-icon v-else-if="doc.document_type =='link'" color="blue darken-2" >mdi-link-box-variant</v-icon>
                              <v-icon v-else-if="doc.document_type =='video'" color="blue-grey darken-3" >mdi-video-box</v-icon>
                              <v-icon v-else-if="doc.document_type =='other'" color="deep-purple darken-2" >mdi-text-box</v-icon>
                            
                              <span class="overline grey--text">
                              {{ doc.document_type }}
                              </span>
                            </v-col>
                           --> 
                            <v-col cols="12" lg="9">
                              <h4 class=" grey--text text--darken-2">{{ doc.document_name }}</h4>
                              <div class="grey--text text--darken-2">{{ doc.document_description }}</div>
                              <div>
                                <v-icon v-if="doc.document_type =='pdf'" color="red darken-2" >mdi-file-pdf-box</v-icon>
                                <v-icon v-else-if="doc.document_type =='link'" color="blue darken-2" >mdi-link-box-variant</v-icon>
                                <v-icon v-else-if="doc.document_type =='video'" color="blue-grey darken-3" >mdi-video-box</v-icon>
                                <v-icon v-else-if="doc.document_type =='other'" color="deep-purple darken-2" >mdi-text-box</v-icon>
                              
                                <span class="overline grey--text">
                                {{ doc.document_type }}
                                </span>
                              </div>
                            </v-col>
                            <v-col cols="12" lg="3" class="text-left">                              

                              <div v-if="doc.document_type =='pdf'">   
                                <v-btn
                                  outlined
                                  color="#b45b38"
                                  @click="viewDocument(doc)"
                                  x-small
                                >
                                  View / Download
                                </v-btn>
                              </div>                           
                              <div v-if="doc.document_type =='video'">   
                                <v-btn
                                  outlined
                                  color="#b45b38"
                                  @click="viewDocument(doc)"
                                  x-small
                                >
                                  Watch Video
                                </v-btn>
                              </div>
                              <div v-if="doc.document_type =='link'">   
                                <v-btn
                                  outlined
                                  color="#b45b38"
                                  @click="viewDocument(doc)"
                                  x-small
                                >
                                  Open Link
                                </v-btn>
                              </div>
                              <div v-if="doc.document_type =='other'">   
                                <v-btn
                                  outlined
                                  color="#b45b38"
                                  @click="viewDocument(doc)"
                                  x-small
                                >
                                  Download
                                </v-btn>
                              </div>

                            </v-col>
                          </v-row>
                        </div>
                        <div v-if="!item.data.length">No content at this time</div>

                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  
                </v-card-text>
              </v-card>
              
            </v-col>
          </v-row>
        </v-container>
      </div>  
    </div>
    <v-dialog
      v-model="videoDialogList"
      width="800"
    >
      <v-card class="pa-5">
        <iframe width="100%" height="450" :src="docVideoLinkList" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import jwt_decode from "jwt-decode"

export default {
  name: 'Home',

  data: () => ({
    categoryTiles: [],
    guestDocuments: [],
    adminApproved: true,
    docVideoLinkList: null,
    videoDialogList: false,
  }),
  beforeMount () {
 
    this.$root.$emit("_pageInit")

    this.checkAdminApproved()

    setTimeout(()=>{
      this.categoryTiles = this.$category.items
    }, 500 )



  },
  mounted (){
    this.getGuestContent()
  },
  methods: {
    checkAdminApproved () {
      const userObj = JSON.parse(localStorage.getItem('rccpcst'))
      const userInfo = jwt_decode(userObj.jwt)
      if(userInfo.ap == 0){
         this.adminApproved = false
      }
    },
    getGuestContent () {

      const userObj = JSON.parse(localStorage.getItem('rccpcst'))
      
      var endpoint = `/portal/content/guestContent`
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
    
      const request = new Request(url, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        mode: 'cors',
      })  
      fetch(request)
      .then(response => {
        return response.json()
      })
      .then((jsonResponse) => {
       
        this.guestDocuments = jsonResponse.data        
      })

    },
    viewDocument (item) {

      const userObj = JSON.parse(localStorage.getItem('rccpcst'))
      const jwt = userObj.jwt
      var docId = item.document_id
      var url, endpoint

      if( item.document_type == 'pdf' || item.document_type == 'other' ) {

        endpoint = `/portal/view-file/${docId}/${jwt}`
        url = process.env.VUE_APP_API_BASE_URL + endpoint
        window.open(url)

      }else if ( item.document_type == 'link' ) {

        url = item.document_url
        window.open(url)
        
      } else if ( item.document_type == 'video' ) {

        this.docVideoLinkList = item.document_url
        this.videoDialogList = true

      }

    },

  },
  components: {
  }
};
</script>

<style lang="scss" scoped>
header{
  width: 100%;
  height: 200px;
  //background: url( '../assets/header-bg.png') no-repeat center top;
  background: url( '../assets/portal-header-bg-3.jpg') no-repeat center top;
  background-size: cover;
  margin-top:-64px;
  color: #fff;
  padding-top: 50px;
  margin-bottom:50px;
}
#page-header{
  font-size: 2.6rem;
  text-transform: uppercase;
}
.cat-icon{
  border-radius: 50%;
  height:70px;
  width: 70px;
  line-height: 70px;
  display: inline-block;
}
#guest-container{
  background-color: #f0f0f0;
}
@media only screen and (max-width: 1024px) {
  #page-header{
    font-size: 1.6rem;
    text-transform: uppercase;
  }
  header{
    width: 100%;
    height: 200px;
    background: url( '../assets/header-bg.png') no-repeat center top;
    background-size: cover;
    margin-top:0px;
    color: #fff;
    padding-top: 50px;
    margin-bottom:50px;
  }
}
</style>

