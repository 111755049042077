import Vue from 'vue'
import VueRouter from 'vue-router'
import SignIn from '../views/SignIn.vue'
import CreateAccount from '../views/CreateAccount.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'
import Home from '../views/Home.vue'
import Logout from '../views/Logout.vue'
// import Category from '../views/Category.vue' //v1
import CategoryView from '../views/CategoryView.vue' //v2
import ContentPage from '../views/ContentPage.vue'
import MyAccount from '../views/MyAccount.vue'
import SearchView from '../views/SearchView.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'SignIn',
    component: SignIn
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/create-account',
    name: 'CreateAccount',
    component: CreateAccount
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  /*
  {
    path: '/category/:slug', 
    name: 'Category',
    component: Category
  },
  */
  {
    path: '/:id/:slug',
    name: 'CategoryView',
    component: CategoryView
  },
  {
    path: '/my-account',
    name: 'MyAccount',
    component: MyAccount
  },
  {
    path: '/search',
    name: 'SearchView',
    component: SearchView
  },
  {
    path: '/:slug',
    name: 'ContentPage',
    component: ContentPage
  },
]

const router = new VueRouter({
  routes
})

export default router

router.beforeEach((to, from, next) => {

  const publicPages = ['/','/create-account','/forgot-password','/reset-password','/logout'];
  const authRequired = !publicPages.includes(to.path);

  //verify jwt is set
  const loggedIn = localStorage.getItem('rccpcst');
  
  if(authRequired && loggedIn){
    
    const userObj = JSON.parse(loggedIn)

    var endpoint = '/portal/auth'

    const url = process.env.VUE_APP_API_BASE_URL + endpoint

    const request = new Request(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userObj.jwt
      },
    })

    fetch(request)
    .then(response => {
      return response.json()
    })
    .then((jsonResponse) => {
      
      if( !jsonResponse.authorized ){
        
        localStorage.clear()
        localStorage.removeItem('rccpcst')
        
        next('/logout')
      }
    })

  }
  
  if (authRequired && !loggedIn) {
    localStorage.clear()
    localStorage.removeItem('rccpcst')
    next('/logout')
  } else {
    next()
  }
})
