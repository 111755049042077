<template>
  <div>
    <header class="text-center">
      <div class="overline">RareCyte Portal</div>
      <div id="page-header" class="font-weight-thin">My Account</div>
    </header>

    <div id="content-container">
      <v-container>
        <div v-if="adminApproved">
          <v-row justify="center" class="">
            <v-col cols="12" lg="5" md="6"
            >
              <v-card outlined>
                <v-list>
                  <v-list-item
                    @click="dialogName=true"
                  >  
                    <v-list-item-content>
                      <v-list-item-title>Full Name</v-list-item-title>
                      <v-list-item-subtitle>{{ customerFirstName }} {{ customerLastName }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon>
                        <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>

                <v-list>
                  <v-divider></v-divider>
                  <v-list-item
                  @click="dialogCompany=true"
                  >
                    <v-list-item-content>
                      <v-list-item-title>Company/Institution</v-list-item-title>
                      <v-list-item-subtitle>{{ customerCompany }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon>
                        <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>

                <v-list>
                  <v-divider></v-divider>
                  <v-list-item
                    @click="dialogEmail=true"
                  >
                    <v-list-item-content>
                      <v-list-item-title>Email</v-list-item-title>
                      <v-list-item-subtitle>{{ customerEmail }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon>
                        <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>

                <v-list>
                  <v-divider></v-divider>
                  <v-list-item
                    @click="dialogPhone=true"
                  >
                    <v-list-item-content>
                      <v-list-item-title>Phone</v-list-item-title>
                      <v-list-item-subtitle>{{ customerPhone }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon>
                        <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>

                <v-list>
                  <v-divider></v-divider>
                  <v-list-item
                    @click="dialogPassword=true"
                  >
                    <v-list-item-content>
                      <v-list-item-title>Update Password</v-list-item-title>

                      <v-list-item-subtitle>Click to Update Password</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon>
                        <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
                  
              </v-card>
            </v-col>
          </v-row>
        </div>
        <div v-else class="pb-8">
          <v-row justify="center">
            <v-col cols="12" lg="4">
              <v-alert
                icon="mdi-alert"
                prominent
                text
                type="warning"
              >
                Your account is still under review.  
              </v-alert>
            </v-col>
          </v-row>
        </div>  
      </v-container>
    </div>

    <!-- Dialog - Name -->
    <v-dialog
      v-model="dialogName"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-form
          ref="formName"
          v-model="validName"
          lazy-validation
        >
          <v-card-title>
            <span class="text-h6 mb-5">Customer Name</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="form.customerFirstName"
              label="First name*"
              :rules="[v => !!v || 'First name required',]"
              required
              outlined
            ></v-text-field>
            <v-text-field
              v-model="form.customerLastName"
              label="Last name*"
              :rules="[v => !!v || 'Last name required',]"
              required
              outlined
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            
            <v-spacer></v-spacer>
            
            <v-btn
              text
              @click="dialogName = false, getCustomer()"
            >
              Close
            </v-btn>
            
            
            <v-btn
              :loading="loading"
              :disabled="loading"
              color="secondary"
              elevation="0"
              @click="updateCustomer('name')"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form> 
      </v-card>
    </v-dialog>

    <!-- Dialog - Company -->
    <v-dialog
      v-model="dialogCompany"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-form
          ref="formCompany"
          v-model="validCompany"
          lazy-validation
        >
          <v-card-title>
            <span class="text-h6 mb-5">Company / Institution</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="form.customerCompany"
              label="Company / Institution *"
              outlined
              required
              :rules="[
                v => !!v || 'Company / Institution name is required'
              ]"
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="dialogCompany = false, getCustomer()"
            >
              Close
            </v-btn>
            <v-btn
              :loading="loading"
              :disabled="loading"
              color="secondary"
              elevation="0"
              @click="updateCustomer('company')"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form> 
      </v-card>
    </v-dialog>

    <!-- Dialog - Email -->
    <v-dialog
      v-model="dialogEmail"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-form
          ref="formEmail"
          v-model="validEmail"
          lazy-validation
        >
          <v-card-title>
            <span class="text-h6 mb-5">Email</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="form.customerEmail"
              label="Email *"
              :rules= "[
                v => !!v || 'User Email is required',
                v => /.+@.+\..+/.test(v) || 'Email must be valid',
              ]"
              required
              outlined
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="dialogEmail = false, getCustomer()"
            >
              Close
            </v-btn>
            <v-btn
              :loading="loading"
              :disabled="loading"
              color="secondary"
              elevation="0"
              @click="updateCustomer('email')"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form> 
      </v-card>
    </v-dialog>

    <!-- Dialog - Phone -->
    <v-dialog
      v-model="dialogPhone"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-form
          ref="formPhone"
          v-model="validPhone"
          lazy-validation
        >
          <v-card-title>
            <span class="text-h6 mb-5">Phone</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="form.customerPhone"
              label="Phone"
              outlined
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="dialogPhone = false, getCustomer()"
            >
              Close
            </v-btn>
            <v-btn
              :loading="loading"
              :disabled="loading"
              color="secondary"
              elevation="0"
              @click="updateCustomer('phone')"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form> 
      </v-card>
    </v-dialog>

    <!-- Dialog - Password -->
    <v-dialog
      v-model="dialogPassword"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-form
          ref="formPassword"
          v-model="validPassword"
          lazy-validation
        >
          <v-card-title>
            <span class="text-h6">Update Password</span>
          </v-card-title>
          <v-card-text>
            <div class="mb-6">
              <ul>
                <li>At least 8 characters</li>
                <li>At least one uppercase letter</li>
                <li>At least one number</li>
              </ul>
            </div>

            <v-text-field
              v-model="form.customerPassword"
              label="New password *"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              :rules= "[
                v => !!v || 'New password is required',
                v => /.{8,}$/.test(v) || 'Password must be at lease 8 characters',
                v => /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(v) || 'Password must contain at least one uppercase and one lowercase letter',
              ]"
              required
              outlined
              @click:append="show1 = !show1"
            ></v-text-field>
            <v-text-field
              v-model="form.customerPasswordMatch"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              label="Confirm password *"
              :type="show2 ? 'text' : 'password'"
              :rules="[
                v => !!v || 'Password must match',
                v => v === this.form.customerPassword || 'Password must match',
              ]"
              required
              outlined
              @click:append="show2 = !show2"
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions> 
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="dialogPassword = false, getCustomer()"
            >
              Close
            </v-btn>
            <v-btn
              :loading="loading"
              :disabled="loading"
              color="secondary"
              elevation="0"
              @click="updateCustomer('password')"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form> 
      </v-card>
    </v-dialog>

  </div>
</template>


<script>
import jwt_decode from "jwt-decode"

export default {
  name: 'MyAccount',

  data: () => ({
    loading: false,
    customerFirstName: '',
    customerLastName: '',
    customerCompany: '',
    customerEmail: '',
    customerPassword: '',
    customerPhone: '',
    form: {
      customerFirstName: '',
      customerLastName: '',
      customerCompany: '',
      customerEmail: '',
      customerPassword: null,
      customerPasswordMatch: null,
      customerPhone: ''
    },
    dialogName: false,
    validName: true,
    dialogCompany: false,
    validCompany: true,
    dialogEmail: false,
    validEmail: true,
    dialogPhone: false,
    validPhone: true,
    dialogPassword: false,
    validPassword: true,
    show1: false,
    show2: false,
    adminApproved: true,
  }),
  beforeMount () {
    this.checkAdminApproved()
    this.getCustomer()
  },
  mounted (){
  },
  methods: {
    getCustomer () {

      const userObj = JSON.parse(localStorage.getItem('rccpcst'))
      var decoded = jwt_decode(userObj.jwt);
      
      var endpoint = `/portal/customers/${decoded.id}`
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
    
      const request = new Request(url, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        mode: 'cors',
      })  
      fetch(request)
      .then(response => {
        return response.json()
      })
      .then((jsonResponse) => {
        
        this.customerFirstName = jsonResponse[0].customer_first
        this.customerLastName = jsonResponse[0].customer_last
        this.customerCompany = jsonResponse[0].customer_company
        this.customerEmail = jsonResponse[0].customer_email
        this.customerPhone = jsonResponse[0].customer_phone

        this.form.customerFirstName = jsonResponse[0].customer_first
        this.form.customerLastName = jsonResponse[0].customer_last
        this.form.customerCompany = jsonResponse[0].customer_company
        this.form.customerEmail = jsonResponse[0].customer_email
        this.form.customerPhone = jsonResponse[0].customer_phone
        
        this.$refs.formPassword.reset()
        
      })
      
    },

    updateCustomer (col) {

      var sql = {}
      var successMsg = ''

      if( col == "name" ){

        if(this.$refs.formName.validate()){
          
          this.loading = true
          sql = {
            customer_first: this.form.customerFirstName,
            customer_last: this.form.customerLastName
          } 

          successMsg = "Your name has been updated"
         
        }else{
          return
        }

      } else if ( col == 'company') {

        if(this.$refs.formCompany.validate()){
          
          this.loading = true
          
          sql = {
            customer_company: this.form.customerCompany
          } 
          successMsg = "Your company / institution has been updated"

        }else{
          return
        }

      } else if ( col == 'email') {

        if(this.$refs.formEmail.validate()){
          this.loading = true
          sql = {
            customer_email: this.form.customerEmail
          } 
          successMsg = "Your email address has been updated"
        } else {
          return
        }
        
      } else if ( col == 'phone') {

        this.loading = true
        sql = {
          customer_phone: this.form.customerPhone
        } 
        successMsg = "Your phone number has been updated"
       
      } else if ( col == 'password') {

        if(this.$refs.formPassword.validate()){
          this.loading = true
          sql = {
            customer_password: this.form.customerPassword
          } 
          successMsg = "Your password has been updated"
        } else {
          return
        }

      }
      

      let postData = {
        action: "update",
        sql: sql
      }

      const userObj = JSON.parse(localStorage.getItem('rccpcst'))
      var decoded = jwt_decode(userObj.jwt);
      
      var endpoint = `/portal/customers/${decoded.id}`
      const url = process.env.VUE_APP_API_BASE_URL + endpoint

      const request = new Request(url, {
        method: 'PUT',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData),
        mode: 'cors',
      })

      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {
 
        if(jsonResponse.affectedRows){

          setTimeout(()=>{
            this.loading = false
            this.$root.$emit("notify","success",successMsg)
            this.getCustomer()
          },500)
          setTimeout(()=>{
            this.dialogName = false
            this.dialogCompany = false
            this.dialogEmail = false
            this.dialogPhone = false
            this.dialogPassword = false
          },500)
          
        }else{

          setTimeout(()=>{
            this.$root.$emit("notify","error","There was an error processing this request")
            this.loading = false 
          },500)
          
        }
      })
      
    },

    checkAdminApproved () {
      const userObj = JSON.parse(localStorage.getItem('rccpcst'))
      const userInfo = jwt_decode(userObj.jwt)
      if(userInfo.ap == 0){
         this.adminApproved = false
      }
    },
  },
  components: {
  }
};
</script>

<style lang="scss" scoped>
header{
  width: 100%;
  height: 200px;
  background: url( '../assets/header-bg.png') no-repeat center top;
  background-size: cover;
  margin-top:-64px;
  color: #fff;
  padding-top: 50px;
  margin-bottom:50px;
}
#page-header{
  font-size: 2.6rem;
  text-transform: uppercase;
}
@media only screen and (max-width: 1024px) {
  #page-header{
    font-size: 1.6rem;
    text-transform: uppercase;
  }
  header{
    width: 100%;
    height: 200px;
  //  background: url( '../assets/header-bg.png') no-repeat center top;
    background: url( '../assets/portal-header-bg-3.jpg') no-repeat center top; 
    background-size: cover;
    margin-top:0px;
    color: #fff;
    padding-top: 50px;
    margin-bottom:50px;
  }
}
</style>

