<template>
  <div>
    <header class="pageHeader text-center">
      <div  v-if="!adminApproved" class="pt-10"></div>
      <div id="page-header" class="font-weight-light">{{ pageTitle }}</div>
    </header>  
    <v-container>

      <div v-if="adminApproved">
        <v-row>
          <v-col cols="12" lg="3">

            <!-- begin: Bread Crumbs -->
            <v-breadcrumbs class="ml-n5 mt-n5" :items="breadcrumbs"></v-breadcrumbs>
            <!-- end: Bread Crumbs -->

            <!-- Subcategory Links -->
            <v-sheet
              v-if="subNavLinks.level1.length > 0"
              rounded
               outlined
              class="pt-3 px-6 pb-6  mb-5"
            >
              <h4 class="overline filter-header">Subcategories</h4>
              <div 
                v-for="level1 in subNavLinks.level1"
                :key="level1.category_id"
                class="my-2"
              >
                <router-link class="subnav-link" :to="'/' + level1.category_id + '/' + level1.category_slug"> {{ level1.category_name }} ({{ level1.ttlDocs }}) </router-link> 
              </div>
            </v-sheet>
            
            <v-sheet
              v-if="subNavChecks.level1.length > 0"
              rounded
               outlined
              class="pt-3 px-6 pb-6 mb-5"
            >
              <h4 class="overline filter-header">Subcategories</h4>
              <div class=""></div>
              <v-checkbox
                v-model="filterCbs.level1"
                v-for="level1 in subNavChecks.level1"
                :key="level1.category_id"
                class="mb-n6"
                :value="level1.category_id"
                :label="level1.category_name + ' (' + level1.ttlDocs + ')'"
                @change="parseDocs($event, level1.category_id, level1.category_name)"
              ></v-checkbox>
            </v-sheet>

            <v-sheet
              rounded
              outlined
              class="pt-3 px-6 pt-4 pb-2 mb-5"
            >
              <h4 class="overline filter-header">Filter Results</h4>
              <v-text-field
                v-model="search"
                label="Filter Results"
                class=""
                outlined
              ></v-text-field>
            </v-sheet>

            <v-sheet
              v-if="subNavChecks.level2.length > 0"
              rounded
               outlined
              class="pt-3 px-6 pb-6 mb-5"
            >
              <v-checkbox
                v-model="filterCbs.level2"
                v-for="level2 in subNavChecks.level2"
                :key="level2.category_id"
                class="mb-n6"
                :value="level2.category_id"
                :label="level2.category_name + ' (' + level2.ttlDocs + ')'"
                @change="parseDocs($event, level2.category_id, level2.category_name)"
              ></v-checkbox>
            </v-sheet>

            <v-sheet
              v-if="subNavChecks.level3.length > 0"
              rounded
               outlined
              class="pt-3 px-6 pb-6 mb-5"
            >
              <v-checkbox
              v-model="filterCbs.level3"
                v-for="level3 in subNavChecks.level3"
                :key="level3.category_id"
                class="mb-n6"
                :value="level3.category_id"
                :label="level3.category_name + ' (' + level3.ttlDocs + ')'"
                @change="parseDocs($event, level3.category_id, level3.category_name)"
              ></v-checkbox>
            </v-sheet>
          </v-col>
          <v-col cols="12" lg="9">

            <v-row justify="center">
              <v-col cols="10">
                
                <h1 class="mb-1 font-weight-thin text-uppercase">{{ documentPageTitle }}</h1>
                
                <!--
                <div class="mb-6">
                 <strong>{{ foundDocs }}</strong> items found
                </div>
                -->

                <!-- begin: Chips -->
                <div class="mt-6 mb-6">
                  <v-chip
                    v-for="filters in filterChips"
                    :key="filters.id"
                    class="mt-2 mr-2 mb-2"
                    close
                    @click:close="parseDocs('chip', filters.id, filters.name)"
                  >
                    {{ filters.name }}
                  </v-chip>
                  <v-btn
                    v-if="filterChips.length > 0"
                    text
                    rounded
                    small
                    color="info"
                    @click="clearFilters"
                  >
                    Clear All
                  </v-btn>
                </div>
                <!-- end: Chips -->

                <!-- begin: DataTable -->
                <v-data-table
                  class="elevation-0"
                  :headers="headers"
                  :items="portalContent"
                  :search="search"
                  :disable-pagination="true"
                  :items-per-page="-1"
                  :hide-default-footer="true"
                  :hide-default-header="true"
                >
                  <template v-slot:item.document_name="{ item }">
                    <div class="py-5">
                      <h2 class="font-weight-regular mb-1">{{ item.document_name }}</h2>
                      <div v-if="item.document_type =='pdf'">
                        <v-icon class="mt-n1 mr-1" color="red darken-2" small>mdi-file-pdf-box</v-icon> 
                        <span class="grey--text overline">PDF Document</span>
                      </div>
                      <div v-if="item.document_type =='video'">
                        <v-icon class="mt-n1 mr-1" color="blue-grey darken-3" small>mdi-video-box</v-icon> 
                        <span class="grey--text overline">Video</span>
                      </div>
                      <div v-if="item.document_type =='link'">
                        <v-icon class="mt-n1 mr-1" color="blue darken-2" small>mdi-link-box-variant</v-icon> 
                        <span class="grey--text overline">Link</span>
                      </div>
                      <div v-if="item.document_type =='other'">
                        <v-icon class="mt-n1 mr-1" color="deep-purple darken-2" small>mdi-text-box</v-icon> 
                        <span class="grey--text overline">Link</span>
                      </div>
                      <div class="mt-4">{{ item.document_description }}</div>
                    </div>
                  </template>

                  <template v-slot:item.document_description="{ item }">
                    <div style="display:none">   {{ item.document_description }}</div>
                  </template>

                  <template v-slot:item.document_id="{ item }">
                    <div v-if="item.document_type =='pdf'">   
                      <v-btn
                        outlined
                        color="#b45b38"
                        @click="viewDocument(item)"
                        small
                      >
                        View / Download
                      </v-btn>
                    </div>
                    <div v-if="item.document_type =='video'">   
                      <v-btn
                        outlined
                        color="#b45b38"
                        @click="viewDocument(item)"
                        small
                      >
                        Watch Video
                      </v-btn>
                    </div>
                    <div v-if="item.document_type =='link'">   
                      <v-btn
                        outlined
                        color="#b45b38"
                        @click="viewDocument(item)"
                        small
                      >
                        Open Link
                      </v-btn>
                    </div>
                    <div v-if="item.document_type =='other'">   
                      <v-btn
                        outlined
                        color="#b45b38"
                        @click="viewDocument(item)"
                        small
                      >
                        Download
                      </v-btn>
                    </div>
                  </template>
                </v-data-table>
                <!-- end: DataTable -->
              </v-col>
            </v-row>

          </v-col>
        </v-row>
      </div> 
      <div v-else>
        
        <v-container>
          <v-row justify="center">
            <v-col cols="12" lg="4">
              <v-alert
                icon="mdi-alert"
                prominent
                text
                type="warning"
              >
                Your account is still under review. To view available guest content, please click the button below.  
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <v-btn
                link
                to="/home"
                elevation="1"
              >
                View Guest Content
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
        
      </div> 
    </v-container>

    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <v-dialog
      v-model="videoDialogList"
      width="800"
    >
      <v-card class="pa-5">
        <iframe width="100%" height="450" :src="docVideoLinkList" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
import jwt_decode from "jwt-decode"

export default {
  name: 'Category',
  data: () => ({
    breadcrumbs:  [],
    subNavLinks: {
      level1: [],
      level2: [],
      level3: [],
      level4: []
    },
    subNavChecks: {
      level1: [],
      level2: [],
      level3: [],
      level4: []
    },
    subNavChecks_selected: {
      level1: [],
      level2: [],
      level3: [],
      level4: []
    },
    filterCbs: {
      level1: [],
      level2: [],
      level3: [],
      level4: []
    },
    filterChips: [],
    productCategories: [],
    productCategoriesParsed: [],
    foundDocs: [],
    documentPageTitle: '',
    search: '',
    calories: '',
    portalContent: [],
    overlay: false,
    pageTitle: '',
    adminApproved: true,
    showTable: false,
    itemsPerPageArray: [10, 25, 50],
    filter: {},
    sortDesc: false,
    page: 1,
    itemsPerPage: 10,
    sortBy: 'name',
    keys: [],
    items: [],
    docVideoLinkList: null,
    videoDialogList: false, 
    count: 0
  }),
  computed: {
    headers () {
        return [
          {
            text: '',
            align: 'start',
            sortable: false,
            value: 'document_name',
          },
          { text: '', value: 'document_description' },
          { text: '', align: 'end', value: 'document_id' },
        ]
      },
    /*
    numberOfPages () {
      return Math.ceil(this.items.length / this.itemsPerPage)
    },
    filteredKeys () {
      return this.keys.filter(key => key !== 'Name')
    },
    */
  },
  beforeMount () {
    
    //this.$root.$emit("_getNavigation")
    this.getSubNavGutter()
  },
  mounted (){

    
    
    //this.getCategoryContent()
    //const userObj = JSON.parse(localStorage.getItem('rccpcst'))
    //console.log(userObj.jwt)
  },
  methods: {

    getSubNavGutter(){

      //reset
      this.search = ''
      this.breadcrumbs =[]
      
      this.subNavLinks.level1 = []
      this.subNavLinks.level2 = []
      this.subNavLinks.level3 = []
      this.subNavLinks.level4 = []
      this.subNavChecks.level1 = []
      this.subNavChecks.level2 = []
      this.subNavChecks.level3 = []
      this.subNavChecks.level4 = []
      
      this.productCategories = []

      let parentId = this.$route.params.id
      
      const userObj = JSON.parse(localStorage.getItem('rccpcst'))
      const userInfo = jwt_decode(userObj.jwt)
      if(userInfo.ap == 0){
        setTimeout(()=>{
          this.adminApproved = false
          this.overlay = false
          return
        },1000)
      }

      
      var endpoint = `/portal/category-filters/${parentId}/${userInfo.pId}`
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
      const request = new Request(url, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        mode: 'cors',
      })
      
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {

        this.portalContent = []

        if(!jsonResponse.success){
          //handle no records found
          return
        }

        //Breadcrumbs
        let breadCrumbs = [] 
        breadCrumbs.push({
          text: 'Home',
          disabled: false,
          href: '/#/',
        })

        let breadCrumbResponse = jsonResponse.breadCrumbs
        for (let i = 0; i < breadCrumbResponse.length; i++) {
          let breadCrumbTempObj = {
            text: breadCrumbResponse[i].title,
            disabled: false,
            href: `/#/${breadCrumbResponse[i].id}/${breadCrumbResponse[i].slug}`
          }
          breadCrumbs.push(breadCrumbTempObj)

        }
        this.breadcrumbs = breadCrumbs

        let titleI = breadCrumbResponse.length
        this.documentPageTitle = breadCrumbs[titleI].text
        this.pageTitle = breadCrumbs[titleI].text
        
        
        //GUTTER FILTERS
        //Level 1
        if(jsonResponse.level1.type){
          
          let level1Data = jsonResponse.level1
          if(level1Data.type === 'link'){
            this.subNavLinks.level1 = level1Data.data
          }else if( level1Data.type === 'cb' ){
            this.subNavChecks.level1 = level1Data.data
          }

          //Level 2
          if(jsonResponse.level2){
            let level2Data = jsonResponse.level2
            this.subNavChecks.level2 = level2Data.data
          }

          //Level 3
          if(jsonResponse.level3){
            let level3Data = jsonResponse.level3
            this.subNavChecks.level3 = level3Data.data
          }

        }

        /*
        //Level 1
        if(jsonResponse.level1){
          let level1Data = jsonResponse.level1
          if(level1Data.type === 'link'){
            this.subNavLinks.level1 = level1Data.data
          }else if( level1Data.type === 'cb' ){
            this.subNavChecks.level1 = level1Data.data
          }
        }
        //Level 2
        if(jsonResponse.level2){
          let level2Data = jsonResponse.level2
          this.subNavChecks.level2 = level2Data.data
        }

        //Level 3
        if(jsonResponse.level3){
          let level3Data = jsonResponse.level3
          this.subNavChecks.level3 = level3Data.data
        }
        */
        
        this.productCategories = jsonResponse.productCategories
        
        //get products
        this.getDocuments(this.productCategories)
     
      })
    },
    parseDocs(checked, id, name) {
      
      let indexCb = ''
      let index = ''
      let tempArr = []
      let chipObj = {}
      let chipObj2 = {}

      let productCategoriesParsedCurrent = this.productCategoriesParsed
      let filterChipsCurrent = this.filterChips

      if(checked === 'chip'){ // delete chip

        if( this.filterCbs.level1.includes(id) ){
          indexCb = this.filterCbs.level1.indexOf(id)
          if (indexCb > -1) { 
            this.filterCbs.level1.splice(indexCb, 1)
          }
        }else if( this.filterCbs.level2.includes(id) ){
          indexCb = this.filterCbs.level2.indexOf(id)
          if (indexCb > -1) { 
            this.filterCbs.level2.splice(indexCb, 1)
          }
        }else if( this.filterCbs.level3.includes(id) ){
          indexCb = this.filterCbs.level3.indexOf(id)
          if (indexCb > -1) { 
            this.filterCbs.level3.splice(indexCb, 1)
          }
        }else if( this.filterCbs.level4.includes(id) ){
          indexCb = this.filterCbs.level4.indexOf(id)
          if (indexCb > -1) { 
            this.filterCbs.level4.splice(indexCb, 1)
          }
        }

        index = productCategoriesParsedCurrent.indexOf(id)
        if (index > -1) { 
          productCategoriesParsedCurrent.splice(index, 1)
        }

        
        
        for (let i = 0; i < filterChipsCurrent.length; i++) {
          
          if(filterChipsCurrent[i].id != id){
            chipObj2 = {
              id: filterChipsCurrent[i].id,
              name: filterChipsCurrent[i].name
            }
            tempArr.push(chipObj2)
          }
          
        }
        filterChipsCurrent = tempArr

      }else{
        if(!productCategoriesParsedCurrent.includes(id)){
          productCategoriesParsedCurrent.push(id)

          chipObj = {
            id: id,
            name: name
          }
          filterChipsCurrent.push(chipObj)
        }else{

          index = productCategoriesParsedCurrent.indexOf(id)
          if (index > -1) { 
            productCategoriesParsedCurrent.splice(index, 1)
          }

          for (let i = 0; i < filterChipsCurrent.length; i++) {
          
            if(filterChipsCurrent[i].id != id){
              chipObj2 = {
                id: filterChipsCurrent[i].id,
                name: filterChipsCurrent[i].name
              }
              tempArr.push(chipObj2)
            }
            
          }
          filterChipsCurrent = tempArr

        }
      }

      this.productCategoriesParsed = []
      this.productCategoriesParsed = productCategoriesParsedCurrent

      this.filterChips = []
      this.filterChips = filterChipsCurrent

      //get products
      if(this.productCategoriesParsed.length > 0){
        // this.productCategoriesParsed
        this.getDocuments(this.productCategoriesParsed)
      }else{
        // this.productCategories
        this.filterChips = []
        this.getDocuments(this.productCategories)
      }

    },
    clearFilters(){
      this.productCategoriesParsed = []
      this.filterChips = []
      this.filterCbs.level1 = []
      this.filterCbs.level2 = []
      this.filterCbs.level3 = []
      this.filterCbs.level4 = []
      this.getDocuments(this.productCategories)
    },
    getDocuments(arr){

      this.overlay = true

      const userObj = JSON.parse(localStorage.getItem('rccpcst'))
      const userInfo = jwt_decode(userObj.jwt)
      

      this.portalContent = []

      let postData = {
        action: 'getCategory',
        cats: arr,
        acctPermission: userInfo.pId,
        search: null
      }

      var endpoint = `/portal/content`
      const url = process.env.VUE_APP_API_BASE_URL + endpoint
      
      const request = new Request(url, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + userObj.jwt,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData),
        mode: 'cors',
      })
      
      fetch(request)
      .then(response => {
        return response.json();
      })
      .then((jsonResponse) => {
       
        setTimeout(()=>{
          this.portalContent = jsonResponse
          this.foundDocs = jsonResponse.length
          this.overlay = false
        },500)
      })
    },
    viewDocument (item) {

      const userObj = JSON.parse(localStorage.getItem('rccpcst'))
      const jwt = userObj.jwt
      var docId = item.document_id
      var url, endpoint

      if( item.document_type == 'pdf' || item.document_type == 'other' ) {

        endpoint = `/portal/view-file/${docId}/${jwt}`
        url = process.env.VUE_APP_API_BASE_URL + endpoint

        fetch(url)
        .then(response => response.blob())
        .then(data => window.open(URL.createObjectURL(data)))
        /*
        endpoint = `/portal/view-file/${docId}/${jwt}`
        url = process.env.VUE_APP_API_BASE_URL + endpoint
        window.open(url)
        */
      }else if ( item.document_type == 'link' ) {

        url = item.document_url
        window.open(url)
        
      } else if ( item.document_type == 'video' ) {

        this.docVideoLinkList = item.document_url
        this.videoDialogList = true

      }

    },
  },
  /* */
  watch: {
    '$route' () {
     
      this.clearFilters()
      this.getSubNavGutter()
       
    }
  },
};
</script>

<style lang="scss" scoped>

.filter-header{
  color:#53565b;
}
.subnav-link{
  text-decoration: none;
}
header.pageHeader{
  color: #fff;
  width: 100%;
  height: 200px;
  background: url( '../assets/portal-header-bg-3.jpg') no-repeat center top;
  background-size: cover;
  margin-top:-64px;
  
  margin-bottom:50px;
}
#page-header{
  font-size: 2.7rem;
  text-transform: uppercase;
  padding-top:60px;
}
.icon-box{
  height:30px;
  width: 30px;
  text-align: center;
  line-height: 30px;
}

@media only screen and (max-width: 1024px) {
  header.pageHeader{
    width: 100%;
    height: 200px;
    background: url( '../assets/header-bg.png') no-repeat center top;
    background-size: cover;
    margin-top:0px;
    color: #fff;
    padding-top: 20px;
    margin-bottom:50px;
  }
  #page-header{
    font-size: 1.6rem;
    text-transform: uppercase;
  }
}
</style>