<template>
  <div>
    <div id="sign-in-container" class=""></div>
    <div id="sign-up-container">
      <v-container>
        <v-row justify="center">
          <v-col cols="12" lg="8">
            <v-card
              elevation="0"
              class=""
            >
              <v-card-title>
                <v-img id="login-logo" src="../assets/RareCyte_Logo.png" max-width="300" />
              </v-card-title>
              <v-card-text>
                
                <v-scroll-x-transition>
                  <div elevation="0"
                    v-show="showSignUpForm"
                  >
                    <h2 class="font-weight-bold mt-4 mb-6 grey--text text--darken-1">Create Account</h2>
                    <div>
                      Welcome to the RareCyte Customer Portal.  Please complete the form below to sign up to gain access to your personalized RareCyte content.
                    </div>
                    <div class="mt-8">
                      <v-fade-transition>
                        <div v-show="errorAlert" >
                          <v-alert 
                            type="error"
                          >{{errorMsg}}</v-alert>
                        </div>
                      </v-fade-transition>  
                      <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                        @submit.prevent="validate"
                      >
                        <v-row>
                          <v-col cols="12" lg="6">
                            <v-text-field
                              v-model="customerFirstName"
                              label="First Name *"
                              placeholder="First Name"
                              :rules="[
                                v => !!v || 'First name is required'
                              ]"
                              outlined
                              hide-details="auto"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" lg="6">
                            <v-text-field
                              v-model="customerLastName"
                              label="Last Name *"
                              placeholder="Last Name"
                              :rules="[
                                v => !!v || 'Last name is required'
                              ]"
                              outlined
                              required
                              hide-details="auto"
                            ></v-text-field>
                          </v-col>
                        </v-row> 
                        <v-row>
                          <v-col>
                            <v-text-field
                              v-model="customerCompany"
                              label="Company/Institution Name *"
                              placeholder="Company/Institution Name"
                              :rules="[
                                v => !!v || 'Company/Institution name is required'
                              ]"
                              outlined
                              required
                              hide-details="auto"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-text-field
                              v-model="customerPhone"
                              label="Phone"
                              placeholder="Phone"
                              outlined
                              hide-details="auto"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-text-field
                              v-model="customerEmail"
                              label="Email *"
                              placeholder="Email"
                              :rules="[
                                v => !!v || 'Email is required',
                                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                              ]"
                              outlined
                              hide-details="auto"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>  
                        <v-row>
                          <v-col cols="12" lg="7">
                            <v-text-field
                              v-model="customerPassword"
                              label="Password *"
                              placeholder="Password"
                              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="show1 ? 'text' : 'password'"
                              :rules= "[
                                v => !!v || 'Password is required',
                                v => /.{8,}$/.test(v) || 'Password must be at lease 8 characters',
                                v => /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(v) || 'Password must contain at least one uppercase and one lowercase letter',
                              ]"
                              outlined
                              required
                              hint=""
                              @click:append="show1 = !show1"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" lg="5">
                            <ul>
                              <li>at least 8 characters</li>
                              <li>at least one uppercase letter</li>
                              <li>at least one number</li>
                            </ul>
                          </v-col>
                        </v-row>    
                        <p class="mt-2 mb-8 text-center">
                          <em>*RareCyte will not share your information with any third party.</em>
                        </p>
                        <v-btn block
                          elevation="0"
                          color="secondary"
                          large
                          class="ma-2"
                          :loading="loading"
                          :disabled="loading"
                          @click="validate"
                          type="submit"
                        >
                          Create Account
                        </v-btn>
                      </v-form>  
                      <p class="my-8 text-center">
                        Already have an account? 
                        <router-link
                          class="sign-in-link"
                          to="/"
                        >
                          Sign in
                        </router-link>
                      </p>
                    </div>
                  </div> 
                </v-scroll-x-transition>

                <v-scroll-x-transition>
                  <div
                    v-show="showSignUpConfirmation"
                  >
                    
                    <h2 class="font-weight-bold mt-4 mb-6 grey--text text--darken-1">
                      Thank you for signing up for the RareCyte Customer Portal.
                    </h2>
                    
                    <div class="mt-8">
                      <v-alert
                          type="warning"
                          prominent
                          
                        >
                        Please allow one business day for your account to activate. We will notify you at <strong>{{emailConfirmation}}</strong> once we have activated your account.  
                      </v-alert>
                      <p class="my-8">
                        Log in to your account
                        <router-link
                          class="sign-in-link"
                          to="/"
                        >
                          here
                        </router-link>
                        .
                      </p>
                    </div>
                  </div>
                </v-scroll-x-transition>

              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>

  
  </div>
</template>

<script>
export default {
  name: 'CreateAccount',
  data: () => ({
    loading: false,
    valid: true,
    customerFirstName: '',
    customerLastName: '',
    customerCompany: '',
    customerEmail: '',
    customerPassword: '',
    customerPhone: '',
    key: 'b2psa2phZGZvaWxramFkZg==',
    errorMsg: 'There was an error creating your account. Please try again.',
    errorAlert: false,
    emailConfirmation: null,
    showSignUpForm: true,
    showSignUpConfirmation: false,
    show1: false,
  }),
  methods: {
    test (){
      this.showSignUpForm = false

      setTimeout(()=>{
        this.showSignUpConfirmation = true
        
      },300)
      
    },
    validate () {
      
      if( this.$refs.form.validate() ) {
        
        this.loading = true

        var createKey = process.env.VUE_APP_CREATE_CUSTOMER_KEY

        this.emailConfirmation = this.customerEmail

        let postData = {
          customerFirstName: this.customerFirstName,
          customerLastName: this.customerLastName,
          customerCompany: this.customerCompany,
          customerEmail: this.customerEmail,
          customerPhone: this.customerPhone,
          customerPassword: this.customerPassword,
          createKey: createKey
        }

        var endpoint = '/portal/customers'

        const url = process.env.VUE_APP_API_BASE_URL + endpoint
        const request = new Request(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postData),
          mode: 'cors',
        });

        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {

          if(jsonResponse.insertId){
            setTimeout(()=>{
              
              this.showSignUpForm = false

              setTimeout(()=>{
                this.showSignUpConfirmation = true
                this.reset()
              },300)

              this.loading = false

            },1000)
          }else{
            setTimeout(()=>{
              this.loading = false
              this.errorMsg = jsonResponse.msg
              this.errorAlert = true
              this.reset()
              return
            },1000)
            setTimeout(()=>{
              this.errorAlert = false
            },5000)  
          }
        })
      }
    },
    reset () {
      this.$refs.form.reset()
    },
  },
  components: {
  }
}
</script>
<style lang="scss" scoped>
#sign-in-container{
  width: 100%;
  height: 100%;
  background: url( '../assets/sign-in-bg-dark.jpg') no-repeat center top;
  background-size: cover;
  position: fixed;
}
#sign-up-container{
  position: absolute;
  width: 50%;
  min-height: 100%;
  background: #fff;
  top: 0;
  right: 0;
  overflow: auto;
}
.sign-in-link{
  font-weight: bold;
  color: inherit;
}
@media only screen and (max-width: 1023px) {
  #sign-in-container{
    display:none;
  }
  #sign-up-container{
    position: relative;
    display:block;
    background: #fff;
    overflow: auto;
    width:100%;
  }
}
</style>

