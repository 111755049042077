<template>
  <div id="sign-in-container" class="">
    <v-container>
      <v-row id="login-panel" justify="center">
        <v-col cols="12" lg="4" md="8" sm="10">
          <v-card
            elevation="6"
            class="pt-8"
          >
            <v-card-title>
              <v-img id="login-logo" src="../assets/RareCyte_Logo.png" max-width="300" />
            </v-card-title>
            <v-card-text>
              <h2 class="text-center font-weight-bold">Customer Portal Sign In</h2>
              <v-row justify="center" class=" mt-5">
                <v-col cols="10">
                  <v-fade-transition>
                    <div v-show="errorAlert" class="pb-4">
                      <v-alert 
                        type="error"
                      >{{errorMsg}}</v-alert>
                    </div>
                  </v-fade-transition> 
                  <v-form
                    ref="formSignIn"
                    v-model="valid"
                    lazy-validation
                    @submit.prevent="validateSignIn"
                  >
                    <v-text-field
                      v-model="usr"
                      class="mb-5"
                      label="Email"
                      placeholder="Email"
                      outlined
                      prepend-inner-icon="mdi-email-outline"
                      hide-details="auto"
                      :rules= "[
                        v => !!v || 'User Email is required',
                        v => /.+@.+\..+/.test(v) || 'Email must be valid',
                      ]" 
                      required
                    ></v-text-field>
                    <v-text-field
                      v-model="pwd"
                      class="mb-10"
                      label="Password"
                      placeholder="Password"
                      outlined
                      type="password"
                      prepend-inner-icon="mdi-lock-outline"
                      hide-details="auto"
                      :rules="[v => !!v || 'Password is required']"
                      required
                    ></v-text-field>
                    <v-btn block
                      :loading="loading"
                      :disabled="loading"
                      elevation="0"
                      color="secondary"
                      large
                      @click="validateSignIn"
                      type="submit"
                    >
                      Log in
                    </v-btn>
                  </v-form>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-row justify="center" class="pt-5 pb-5">
                <v-col cols="10">
                  <v-row>
                    <v-col>
                      <v-btn
                        elevation="0"
                        color="transparent"
                        x-small
                        link
                        to="/forgot-password"
                      >
                        <v-icon
                          left
                          dark
                        >
                          mdi-lock
                        </v-icon>
                        Forgot your password?
                      </v-btn>
                    </v-col>
                    <v-col class="text-sm-right">
                      <v-btn
                        elevation="0"
                        color="transparent"
                        x-small
                        link
                        to="/create-account"
                      >
                        <v-icon
                          left
                          dark
                        >
                          mdi-account-circle
                        </v-icon>
                        Create an account
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'SignIn',
  data: () => ({
    valid: true,
    loading: false,
    usr: '',
    pwd: '',
    errorAlert: false,
    errorMsg: ''
  }),
  methods: {
    validateSignIn () {

      if( this.$refs.formSignIn.validate() ) {
        
        this.loading = true
        
        let postData = {
          email: this.usr,
          pwd: this.pwd
        }

        var endpoint = '/portal/login'
        const url = process.env.VUE_APP_API_BASE_URL + endpoint
        const request = new Request(url, {
          method: 'POST',
          mode: 'cors',
          body: JSON.stringify(postData),
          headers: {
            'Content-Type': 'application/json'
          },
        })

        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {
          setTimeout(()=>{
            if(!jsonResponse.success){
              this.errorMsg = jsonResponse.msg
              this.errorAlert=true
              setTimeout(()=>{
                this.errorAlert = false
                this.errorMsg = ''
              },3000)
            }else{
              localStorage.setItem('rccpcst', JSON.stringify(jsonResponse.customerData))
              setTimeout(()=>{
                this.$router.push({ path: '/home' })
              },500)
            }           
            this.loading = false
          },500);  
        })
      }
    },
    resetSignIn () {
      this.$refs.formSignIn.reset()
    },
  },
  components: {
  }
}
</script>
<style lang="scss" scoped>
#sign-in-container{
  width: 100%;
  height: 100%;
  background: url( '../assets/sign-in-bg-dark.jpg') no-repeat center top;
  background-size: cover;
}
#login-panel{
  margin-top:100px;
}
#login-logo{
  margin: 0 auto;
}
/*
#form-container{
  position: absolute;
  right:0;
  height: 100%;
  width: 40%;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 16% 100%);
  background-color: #fff;
}
*/


</style>

