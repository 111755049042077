var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{attrs:{"id":"sign-in-container"}}),_c('div',{attrs:{"id":"sign-up-container"}},[_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","lg":"8"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_c('v-img',{attrs:{"id":"login-logo","src":require("../assets/RareCyte_Logo.png"),"max-width":"300"}})],1),_c('v-card-text',[_c('v-scroll-x-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSignUpForm),expression:"showSignUpForm"}],attrs:{"elevation":"0"}},[_c('h2',{staticClass:"font-weight-bold mt-4 mb-6 grey--text text--darken-1"},[_vm._v("Create Account")]),_c('div',[_vm._v(" Welcome to the RareCyte Customer Portal. Please complete the form below to sign up to gain access to your personalized RareCyte content. ")]),_c('div',{staticClass:"mt-8"},[_c('v-fade-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.errorAlert),expression:"errorAlert"}]},[_c('v-alert',{attrs:{"type":"error"}},[_vm._v(_vm._s(_vm.errorMsg))])],1)]),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"label":"First Name *","placeholder":"First Name","rules":[
                              v => !!v || 'First name is required'
                            ],"outlined":"","hide-details":"auto","required":""},model:{value:(_vm.customerFirstName),callback:function ($$v) {_vm.customerFirstName=$$v},expression:"customerFirstName"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"label":"Last Name *","placeholder":"Last Name","rules":[
                              v => !!v || 'Last name is required'
                            ],"outlined":"","required":"","hide-details":"auto"},model:{value:(_vm.customerLastName),callback:function ($$v) {_vm.customerLastName=$$v},expression:"customerLastName"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Company/Institution Name *","placeholder":"Company/Institution Name","rules":[
                              v => !!v || 'Company/Institution name is required'
                            ],"outlined":"","required":"","hide-details":"auto"},model:{value:(_vm.customerCompany),callback:function ($$v) {_vm.customerCompany=$$v},expression:"customerCompany"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Phone","placeholder":"Phone","outlined":"","hide-details":"auto"},model:{value:(_vm.customerPhone),callback:function ($$v) {_vm.customerPhone=$$v},expression:"customerPhone"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Email *","placeholder":"Email","rules":[
                              v => !!v || 'Email is required',
                              v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                            ],"outlined":"","hide-details":"auto","required":""},model:{value:(_vm.customerEmail),callback:function ($$v) {_vm.customerEmail=$$v},expression:"customerEmail"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"7"}},[_c('v-text-field',{attrs:{"label":"Password *","placeholder":"Password","append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show1 ? 'text' : 'password',"rules":[
                              v => !!v || 'Password is required',
                              v => /.{8,}$/.test(v) || 'Password must be at lease 8 characters',
                              v => /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(v) || 'Password must contain at least one uppercase and one lowercase letter',
                            ],"outlined":"","required":"","hint":""},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.customerPassword),callback:function ($$v) {_vm.customerPassword=$$v},expression:"customerPassword"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"5"}},[_c('ul',[_c('li',[_vm._v("at least 8 characters")]),_c('li',[_vm._v("at least one uppercase letter")]),_c('li',[_vm._v("at least one number")])])])],1),_c('p',{staticClass:"mt-2 mb-8 text-center"},[_c('em',[_vm._v("*RareCyte will not share your information with any third party.")])]),_c('v-btn',{staticClass:"ma-2",attrs:{"block":"","elevation":"0","color":"secondary","large":"","loading":_vm.loading,"disabled":_vm.loading,"type":"submit"},on:{"click":_vm.validate}},[_vm._v(" Create Account ")])],1),_c('p',{staticClass:"my-8 text-center"},[_vm._v(" Already have an account? "),_c('router-link',{staticClass:"sign-in-link",attrs:{"to":"/"}},[_vm._v(" Sign in ")])],1)],1)])]),_c('v-scroll-x-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSignUpConfirmation),expression:"showSignUpConfirmation"}]},[_c('h2',{staticClass:"font-weight-bold mt-4 mb-6 grey--text text--darken-1"},[_vm._v(" Thank you for signing up for the RareCyte Customer Portal. ")]),_c('div',{staticClass:"mt-8"},[_c('v-alert',{attrs:{"type":"warning","prominent":""}},[_vm._v(" Please allow one business day for your account to activate. We will notify you at "),_c('strong',[_vm._v(_vm._s(_vm.emailConfirmation))]),_vm._v(" once we have activated your account. ")]),_c('p',{staticClass:"my-8"},[_vm._v(" Log in to your account "),_c('router-link',{staticClass:"sign-in-link",attrs:{"to":"/"}},[_vm._v(" here ")]),_vm._v(" . ")],1)],1)])])],1)],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }